import * as React from 'react'
import Layout from '../../components/layout'
import Card from '../../components/card'
import Header from '../../components/header'
import PostThumbnail from '../../components/post-thumbnail'
import { Link, graphql } from 'gatsby'

const BlogPage = ({ data }) => {
  return (
    <Layout>
      <title>Watch+ Blog</title>
      <Header title="Watch+ Blog" description="USEFUL APPLE WATCH® TRICKS AND TIPS"/>
      <ul>
        {
          data.allMdx.nodes.map(node => (
            <article key={node.id}>
              <Card>
                <Link to={`/blog/${node.slug}`}>
                  <PostThumbnail title={node.frontmatter.title} date={node.frontmatter.date}/>
                </Link>
              </Card>
            </article>
          ))
        }
      </ul>
    </Layout>
  )
}

export const query = graphql`
query MyQuery {
    allMdx {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
        }
        id
        slug
      }
    }
  }
`

export default BlogPage