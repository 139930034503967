import * as React from 'react'
import * as styles from "./header.module.css"

const Header = ({ title, description }) => {
  return (
    <div className={styles.header}>
        <h1>{title}</h1>
        <p>{description}</p>
    </div>
  )
}

export default Header