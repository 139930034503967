import * as React from 'react'
import * as styles from "./post-thumbnail.module.css"

const PostThumbnail = ({ title, date }) => {
  return (
    <div className={styles.postThumbnail}>
        <h2>{title}</h2>
        <p>{date}</p>
    </div>
  )
}

export default PostThumbnail